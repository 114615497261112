import React from "react";
import Routes from "./Routes";
import { AuthProvider } from "contexts/AuthContext";
import { ToastProvider } from "contexts/ToastContext";

function App() {
  return  (
    
    <AuthProvider>
      <ToastProvider>
      <Routes />
      </ToastProvider>
    </AuthProvider>
    
  );
}


export default App;
